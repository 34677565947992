import {defineStore} from 'pinia'
/* eslint-disable */
export const useAuthStore = defineStore('auth',{
    state: () => {
        return {
            token:null,
            baseurl:'https://app.sysmio.com.co/api/'
        }
    },
    actions: {
        async login(cliente){
            const url=this.baseurl + 'validar';
            const rawResponse= await fetch(url,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                },
                body:JSON.stringify(
                    {
                        'cliente':cliente,
                        'usuario_vue':'sysmio',
                        'pass_vue':'r9EYgsY*$YUPbip2fcTwc#ufKQ' 
                    })
            })
            const response = await rawResponse.json();
            if(response.status==false){
                this.token=null
                return false
            }else{
                this.token=response.token
                return true
            }
        }
    }

    })