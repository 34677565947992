import { createRouter,createWebHistory  } from 'vue-router'
import ViewCitas from '@/views/ViewCitas.vue'
import ViewLogin from '@/views/ViewLogin.vue' 
// import useAuthStore from '@/store/auth'
import ViewIndex from '@/views/ViewIndex.vue'

const routes = [
  {
    path: '/',
    redirect:'/index'
  },
  {
    path: '/index',
    name: 'index',
    component: ViewIndex
  },
  {
    path: '/citas/:cliente',
    name: 'citas',
    component: ViewCitas
  },
  {
    path: '/login',
    name: 'login',
    component: ViewLogin,
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
});

// router.beforeEach((to, from, next) => {
//   const Auth = useAuthStore()
//   const isAuth = Auth.token
//   if (to.meta.requireAuth  && isAuth==null) {
//     next({ name: 'login' })
//   } else {
//     next()
//   }
// })

export default router
